import type { FC, ComponentType, Dispatch, SetStateAction } from 'react';
import React, { Fragment, useState } from 'react';
import { Query } from 'react-apollo';
import type { QueryResult } from 'react-apollo';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { Inline, Flex, xcss, Box } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { media } from '@atlaskit/primitives/responsive';

import {
	VIEW_PAGE_CONTENT_HEADER_EXPERIENCE,
	ExperienceStart,
} from '@confluence/experience-tracker';
import { ContentTypesHeader } from '@confluence/content-types-header';
import {
	Breadcrumbs,
	FixedSpaceHeaderContainer,
	FixedSpaceHeaderWithBannerContainer,
} from '@confluence/breadcrumbs';
import {
	Attribution,
	ErrorBoundary,
	ErrorDisplay,
	isUnauthorizedError,
} from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import {
	CONTENT_METADATA_PAGE_RESTRICTIONS_ID,
	CONTENT_METADATA_ATTACHMENTS_ID,
} from '@confluence/web-item';
import { RendererContentState } from '@confluence/content-state';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';
import { LoadableAfterPaint } from '@confluence/loadable';
import { useContentClassification } from '@confluence/data-classification/entry-points/queries';
import { usePageHeightWidthObserver } from '@confluence/page-cover-picture';
import { ConvertPageToFolderBannerLoader } from '@confluence/folder-conversion/entry-points/ConvertPageToFolderBannerLoader';
import { ViewPageDistinctionBanner } from '@confluence/live-pages-features/entry-points/ViewPageDistinctionBanner';
import { fg } from '@confluence/feature-gating';
import { expValEquals } from '@confluence/feature-experiments';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';
import { ContentHeaderPresenceLoader } from '@confluence/team-presence/entry-points/ContentHeaderPresenceLoader';
import { WorkflowStatusLoader } from '@confluence/advanced-workflows/entry-points/WorkflowState';

import { ContentHeaderUnifiedQuery } from './ContentHeaderUnifiedQuery.graphql';
import type { ContentHeaderUnifiedQuery as ContentHeaderUnifiedQueryType } from './__types__/ContentHeaderUnifiedQuery';
import { DEFAULT_CONTRIBUTORS_FETCH_LIMIT } from './defaultQueryVariables';
import { PageMetadataAtl } from './PageMetadataAtl';
import { PageMetadataBanner } from './PageMetadataBanner';
import { SystemContentMetadata } from './SystemContentMetadata';
import { GroupedPageButtons } from './GroupedPageButtons';
import { CONNECT_APP_WEB_FRAGMENT_METRIC } from './perf.config';

const ContentClassificationTagLoader = LoadableAfterPaint({
	name: 'ContentClassificationTag',
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContentClassificationTag" */ '@confluence/data-classification'
			)
		).ContentClassificationTag,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlexBreadcrumbsSpacer = styled.div({
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpacedActionItem = styled.div({
	marginLeft: token('space.050'),
});

const BREADCRUMBS_MIN_WIDTH = '150px'; // needed for responsive breadcrumbs

const breadcrumbsContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	minWidth: BREADCRUMBS_MIN_WIDTH,
});

const breadcrumbsAreaWrapper = xcss({
	flexWrap: 'wrap',
	minWidth: BREADCRUMBS_MIN_WIDTH,
});

const modernizedBreadcrumbsAreaWrapperStyles = xcss({
	flexWrap: 'nowrap',
	flex: '0 1 auto',
	minWidth: 'fit-content',
	gap: 'space.050',
	overflow: 'hidden',

	[media.above.xs]: {
		minWidth: '96px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const Metadata = styled.div((props: MetadataProps) => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	overflow: 'hidden', // needed to hide unpublished lozenge below certain header width
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: props.shouldDisplayClassification ? '20px' : '30px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& .content-metadata-jira': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		marginTop: props.shouldDisplayClassification ? '-5px' : '0',
	},
}));

const verticalSeparatorStyles = xcss({
	borderLeft: `2px solid ${token('color.border')}`,
	height: '16px',
	margin: 'space.075',
});

const contentClassificationTagStyles = xcss({
	flexBasis: '100%',
});

const rightSideHeaderStyles = xcss({
	marginLeft: 'auto',
	alignItems: 'center',
});

const workflowStatusStyles = xcss({ marginLeft: 'space.100' });

const VerticalSeparator = () => <Box xcss={verticalSeparatorStyles} />;

SpacedActionItem.displayName = 'SpacedActionItem';

const EXCLUDED_SYSTEM_IDS = [
	CONTENT_METADATA_PAGE_RESTRICTIONS_ID,
	CONTENT_METADATA_ATTACHMENTS_ID,
];

const SYSTEM_IDS_TO_SHOW_WITH_PAGE_BUTTONS = [CONTENT_METADATA_PAGE_RESTRICTIONS_ID];

type MetadataProps = {
	shouldDisplayClassification: boolean;
};

type RenderContentHeaderProps = {
	contentType: string | null | undefined;
	contentTitle: string | null | undefined;
	isFabricPage: boolean;
	loading: boolean;
	subType: string | null | undefined;
	lastModifiedDate: string | null | undefined;
	isThemed: boolean;
	isContentToolsForcedOpen: boolean;
	setIsContentToolsForcedOpen: Dispatch<SetStateAction<boolean>>;
	isArchived: boolean;
};

type RenderBreadcrumbsProps = {
	contentType: string | null | undefined;
	contentTitle: string | null | undefined;
};

type ContentHeaderProps = {
	spaceKey: string;
	contentId: string;
	actionItems?: ComponentType<any>[];
	versionOverride?: number;
	className?: string;
	showMetadataItems?: boolean;
};

export const ContentHeader: FC<ContentHeaderProps> = ({
	spaceKey,
	contentId,
	actionItems = [],
	versionOverride = null,
	showMetadataItems = true,
}) => {
	const [isContentToolsForcedOpen, setIsContentToolsForcedOpen] = useState<boolean>(false);

	const { isExternalCollaborator } = useIsExternalCollaborator();
	const contentClassificationMetadata = useContentClassification({
		contentId,
		spaceKeyOverride: spaceKey,
		contentStatusContext: 'current-only',
	});
	const { isLivePagesFeatureEnabled } = useIsLivePagesFeatureEnabled();

	const { shouldDisplayClassification } = contentClassificationMetadata;

	const leftRightPaddingOffset = -37;
	const widthLimitForMetadata = 1000;

	const { width } = usePageHeightWidthObserver(false, leftRightPaddingOffset, false);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onFolderConversionBannerButtonClick = () => {
		setIsContentToolsForcedOpen(true);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'convertPageToFolderBannerButton',
				source: 'viewPageScreen',
			},
		}).fire();
	};

	const renderBreadcrumbs = ({ contentType, contentTitle }: RenderBreadcrumbsProps) => {
		const shouldRenderMetadataForPageWidth = () => {
			if (fg('confluence_modernized_breadcrumbs')) {
				return width > widthLimitForMetadata;
			} else {
				return (
					!shouldDisplayClassification ||
					(shouldDisplayClassification && width > widthLimitForMetadata)
				);
			}
		};

		return (
			<Flex
				alignItems="center"
				xcss={[
					breadcrumbsAreaWrapper,
					fg('confluence_modernized_breadcrumbs') && modernizedBreadcrumbsAreaWrapperStyles,
				]}
				testId="breadcrumb"
			>
				<Breadcrumbs contentId={contentId} spaceKey={spaceKey} contentTitle={contentTitle || ''} />
				<Inline alignBlock="center">
					{showMetadataItems && shouldRenderMetadataForPageWidth() && (
						<Metadata
							data-testid="metadata-wrapper"
							shouldDisplayClassification={shouldDisplayClassification}
						>
							<SystemContentMetadata
								key={`metadata-${contentId}`}
								contentId={contentId}
								versionOverride={versionOverride}
								excludeIds={EXCLUDED_SYSTEM_IDS}
							/>
							<PageMetadataBanner
								key={`banner-${contentId}`}
								contentId={contentId}
								versionOverride={versionOverride}
							/>
						</Metadata>
					)}
				</Inline>
				{!fg('confluence_modernized_breadcrumbs') && (
					<Box xcss={contentClassificationTagStyles} testId="content-classification-tag">
						<ContentClassificationTagLoader
							contentId={contentId}
							contentType={contentType || null}
							customSitesTitleComponent
							contentClassificationMetadata={contentClassificationMetadata}
							isEditMode={false}
						/>
					</Box>
				)}
			</Flex>
		);
	};

	const renderContentHeader = ({
		contentType,
		contentTitle,
		loading,
		subType,
		lastModifiedDate,
		isContentToolsForcedOpen,
		setIsContentToolsForcedOpen,
		isArchived,
	}: RenderContentHeaderProps) => {
		const renderContentStatus = () => {
			if (fg('document_workflows')) {
				return (
					<Inline xcss={workflowStatusStyles}>
						<WorkflowStatusLoader />
						<VerticalSeparator />
					</Inline>
				);
			} else if (!expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test')) {
				return <RendererContentState contentId={contentId} isDraft={false} />;
			}
		};

		return (
			<Fragment>
				{fg('confluence_modernized_breadcrumbs') ? (
					renderBreadcrumbs({
						contentType,
						contentTitle,
					})
				) : (
					<Box xcss={breadcrumbsContainerStyles}>
						{renderBreadcrumbs({
							contentType,
							contentTitle,
						})}
					</Box>
				)}
				{fg('confluence_modernized_breadcrumbs') && (
					<Box testId="content-classification-tag">
						<ContentClassificationTagLoader
							contentId={contentId}
							contentType={contentType || null}
							customSitesTitleComponent
							contentClassificationMetadata={contentClassificationMetadata}
							isEditMode={false}
						/>
					</Box>
				)}
				{!fg('confluence_modernized_breadcrumbs') && <FlexBreadcrumbsSpacer />}
				{showMetadataItems && <PageMetadataAtl contentId={contentId} />}

				<Flex alignItems="center" xcss={rightSideHeaderStyles}>
					{!isArchived && fg('confluence_team_presence_general_availability') && (
						<Flex alignItems="center">
							<ContentHeaderPresenceLoader
								contentId={contentId}
								contentType={contentType}
								analyticSource="viewPageScreen"
							/>
							<VerticalSeparator />
						</Flex>
					)}
					{renderContentStatus()}
					<GroupedPageButtons
						contentId={contentId}
						version={versionOverride}
						includeSystemIds={SYSTEM_IDS_TO_SHOW_WITH_PAGE_BUTTONS}
						spaceKey={spaceKey}
						contentType={contentType}
						contentSubType={subType}
						isExternalCollaborator={isExternalCollaborator}
						lastModifiedDate={lastModifiedDate}
						showEdit={!(isLivePagesFeatureEnabled && subType === 'live')}
						isContentToolsForcedOpen={isContentToolsForcedOpen}
						setIsContentToolsForcedOpen={setIsContentToolsForcedOpen}
						isArchived={isArchived}
					/>
				</Flex>

				{actionItems?.map((ActionItem: ComponentType<any>, index) => {
					const actionItemProps = {
						contentId,
						spaceKey,
						contentType,
					};
					return (
						<SpacedActionItem
							data-testid="spacedActionItemTestId"
							key={ActionItem?.displayName || index}
						>
							<ActionItem {...actionItemProps} disabled={loading} />
						</SpacedActionItem>
					);
				})}
			</Fragment>
		);
	};

	const renderContentTypesHeader = () => {
		return (
			<ContentTypesHeader
				contentId={contentId}
				isConvertToFolderHighlighted={isContentToolsForcedOpen}
				setIsConvertToFolderHighlighted={setIsContentToolsForcedOpen}
			/>
		);
	};

	return (
		<ErrorBoundary attribution={Attribution.BACKBONE}>
			{showMetadataItems && (
				<PageSegmentLoadStart
					key={`PageSegmentLoadStart-connect-${contentId}`}
					metric={CONNECT_APP_WEB_FRAGMENT_METRIC}
				/>
			)}
			<ExperienceStart id={contentId} name={VIEW_PAGE_CONTENT_HEADER_EXPERIENCE} />
			<Query
				query={ContentHeaderUnifiedQuery}
				variables={{
					contentId,
					spaceKey,
					// Don't omit versionOverride, embeddedContentRender and limit
					// It's an Apollo bug that if preloader these needs to be match preloaded query
					// Also doesn't work if removing them from preloader variables HOT-109035
					versionOverride: versionOverride || null,
					embeddedContentRender: null,
					limit: DEFAULT_CONTRIBUTORS_FETCH_LIMIT,
					useNewContentTopper: true,
				}}
				context={{ single: true }}
			>
				{({ loading, error, data }: QueryResult<ContentHeaderUnifiedQueryType>) => {
					if (error) {
						if (isUnauthorizedError(error)) {
							markErrorAsHandled(error);
							return null;
						} else {
							// TODO: unknown errors should have a nicer UI within `<ErrorDisplay>`, but we don't have such UI for now
							return <ErrorDisplay error={error} />;
						}
					} else if (!data) {
						return null;
					}

					const contentNode = data?.content?.nodes?.[0];
					const contentVersion = contentNode?.version?.number;
					const contentType = contentNode?.type;
					const lastModifiedDate = contentNode?.metadata?.lastModifiedDate;
					const subType = contentNode?.subType;
					const contentTitle = contentNode?.title;
					// property can be "v2" or v2
					const isFabricPage =
						contentNode?.properties?.nodes?.[0]?.value === '"v2"' ||
						contentNode?.properties?.nodes?.[0]?.value === 'v2';
					const spaceIsThemed = Boolean(data?.space?.theme?.themeKey);
					const isArchived = contentNode?.status === 'archived';

					let viewPageDistinctionBanner: JSX.Element | null = null;
					let convertPageToFolderBanner: JSX.Element | null = null;

					if (fg('confluence_live_pages_ab_test_opted_in')) {
						viewPageDistinctionBanner = (
							<ViewPageDistinctionBanner
								contentId={contentId}
								spaceKey={spaceKey}
								versionNumber={contentVersion}
								waitForFolderBanner={fg('confluence_enable_page_to_folder_conversion')}
							/>
						);
					}

					if (fg('confluence_enable_page_to_folder_conversion')) {
						convertPageToFolderBanner = (
							<ConvertPageToFolderBannerLoader
								contentId={contentId}
								spaceKey={spaceKey}
								contentType={contentType}
								onActionButtonClick={onFolderConversionBannerButtonClick}
							/>
						);
					}

					const contentHeader =
						isFabricPage && fg('confluence_frontend_object_header')
							? renderContentTypesHeader()
							: renderContentHeader({
									contentType,
									contentTitle,
									isFabricPage,
									loading,
									subType,
									lastModifiedDate,
									isThemed: spaceIsThemed,
									isContentToolsForcedOpen,
									setIsContentToolsForcedOpen,
									isArchived,
								});

					if (viewPageDistinctionBanner || convertPageToFolderBanner) {
						const banners = (
							<>
								{viewPageDistinctionBanner}
								{convertPageToFolderBanner}
							</>
						);
						return (
							<FixedSpaceHeaderWithBannerContainer
								isThemed={spaceIsThemed}
								testId="content-header-container"
								banner={banners}
							>
								{contentHeader}
							</FixedSpaceHeaderWithBannerContainer>
						);
					}

					return (
						<FixedSpaceHeaderContainer
							isThemed={spaceIsThemed}
							data-testid="content-header-container"
							isModernizedBreadcrumbsEnabled={fg('confluence_modernized_breadcrumbs')}
							isObjectHeaderEnabled={fg('confluence_frontend_object_header')}
						>
							{contentHeader}
						</FixedSpaceHeaderContainer>
					);
				}}
			</Query>
		</ErrorBoundary>
	);
};
